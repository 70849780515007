// 
// user.scss
// Use this to write your custom SCSS
//

.markdown-output h1, #id_comment_preview h1 {
  font-size: $font-size-base * 1.75;
  font-weight: 500;
}

.markdown-output h2, #id_comment_preview h2 {
  font-size: $font-size-base * 1.6;
  font-weight: 500;
}

.markdown-output h3, #id_comment_preview h3 {
  font-size: $font-size-base * 1.45;
  font-weight: 500;
}

.markdown-output h4, #id_comment_preview h4 {
  font-size: $font-size-base * 1.3;
  font-weight: 500;
}

.markdown-output h5, #id_comment_preview h5 {
  font-size: $font-size-base * 1.15;
  font-weight: 500;
}

.markdown-output h6, #id_comment_preview h6 {
  font-size: $font-size-base * 1.0;
  font-weight: 500;
}

.markdown-output pre {
	margin-bottom: 1rem;
}

.markdown-output img {
	max-height: 32rem;
}

.markdown-output {
  word-break: break-word;
}

.video-js .vjs-time-control {
	display:block;
}

.video-js .vjs-remaining-time {
	display: none;
}

div.dz-max-files-reached {
  display: none;
}


// Variable widths

$utilities: map-merge(
  $utilities,
  (
    "width": map-merge(
      map-get($utilities, "width"),
      (
        responsive: true
      )
    )
  )
);
